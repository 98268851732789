import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";
import "../scss/revamp.scss";
import "../scss/custom_responsivesness.scss";

import CommonTemplate from './common-template';

import get from 'lodash/get'
import {useStaticQuery, graphql } from 'gatsby'

export default function defaultTemplate (props){

  var GQLPage = props.data.glstrapi?.article;
  var GQLModules = GQLPage?.Modules;

  var site_menu = props.data.glstrapi?.allMenus;
  var global_module = props.data.glstrapi?.globalModule;
  var layout = GQLPage?.Layout;
 
  
    return(    
      <CommonTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} site_menu={site_menu} global_module={global_module} department={props?.pageContext?.department}/>
    )
  }

export const pageQuery = graphql`
  query DeafultQuery($articleId: ID!) {

    glstrapi {

      globalModule {
        Client_Reviews_BG {
          url
          alternativeText
        }
      }

      article(id: $articleId, publicationState: LIVE) {
        Alias
        Banner_Content
        Banner_Heading
        Banner_Image {
          url
          alternativeText
        }
        Layout
        Meta_Title
        Meta_Description
        Page_Class
        Read_More_Content
        Page_Content
        Pagename
        id
        imagetransforms
        Form_List
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        select_menu {
          Label
          Primary_URL
          Type
          Sub_Type
          Form_User_ID
          Robots
          popular_searches {
            Content
            Name
          }
          Main_Parent {
            Label
            Primary_URL
          }        
          Secondary_Menu {
            Primary_URL
            Label
            Main_Parent {
              Label
              Primary_URL
            } 
          }
          nested_menus {
            Primary_URL
            Label
          }
        }

        Modules {
          __typename

          ... on GLSTRAPI_ComponentComponentSlider {
            id
            Slider_Module {
              Silder_Heading
              Slider_Content
              Slider_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 2000
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }  
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentReviewsModule {
            id
            __typename
            Heading
            Review_content {
              Review_content
              Review_name
              id
            }
          }

          
          ... on GLSTRAPI_ComponentComponentFaqComponent {
            id
            Heading
            Content
            Accordian_Module {
              Content
              Title
            }
            Faq_Bottom_Text
          }

          ... on GLSTRAPI_ComponentComponentBannerCtAs {            
            Banner_Video_Link
            Banner_Video_Label
            Show_Search_Box
            Show_Banner_Filter
            Banner_CTA_Module {
              CTA_Link
              CTA_Label
              id
              Icon_ClassName
            }
          }

          ... on GLSTRAPI_ComponentComponentBannerBottom {
            Banner_Bottom_Module {
              id
              Link
              Heading
              Content
            }
          }
          
          ... on GLSTRAPI_ComponentComponentIntroComponent {
            id            
            Intro_Content
            Intro_Heading
            CTA_Label
            CTA_Link
            CTA_Label_2
            CTA_Link_2
            Accordion_Module {
              Accordion_Content
              Accordion_Heading
              id
            }
            Intro_Rightside_Module {
              Intro_Rightside_Content
              Intro_Rightside_Heading
              Intro_Rightside_Link
              id
            }

            Team_Section {
              Heading
              Select_People{
                Sort
                people {
                  Name
                  id
                  URL
                  Departments
                  Level
                  people_department{
                    id
                    Name
                  }
                  people_departments {
                    id
                    Name
                  }
                  people_services{
                    id
                    Name
                  }
                  Email_ID
                  Form_Queue_ID
                  Phone_Number
                  Image {
                    alternativeText
                    url
                  }
                  imagetransforms
                  ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                  }
                }
              }
            }

            Intro_Video_Module {
              id
              Video_Link
              Video_Image {
                url
                alternativeText
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentOurServicesComponent {
            id
            Explore_Text
            Heading
            Menu_Links {
              Label
              Custom_Link
              Link_Image{
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 84
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }
              }
              id
              Link {
                id
                Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP
                        width: 84
                        quality: 70
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )    
                    }
                  }
                }
                Label
                Primary_URL
                Secondary_URL
                Main_Parent {
                  Primary_URL
                }
                Secondary_Menu {
                  Primary_URL
                }
                nested_menus {
                  Primary_URL
                }
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesAuctionPhoneNumber {
            id
            __typename
            Publish
            Phone_Number
          }

          ... on GLSTRAPI_ComponentComponentFeaturedComponent {
            id
            Content
            Title
            Property_Type {
              id
              Types
              Tab_Name
              Department
            }
            Auction_Type {
              id
              Type
              Tab_Name
            }
            Available_Lots{
              Sort
              property {
                id
                crm_provider
                display_address
                slug
                status
                search_type
                department
                introtext
                price
                building
                crm_negotiator_id
                extra
                images
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
              }
            }
          }
          
          ... on GLSTRAPI_ComponentComponentContentImageSection {
            id
            CTA_Label
            Content
            Heading
            Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 880
                    quality: 70
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
            Content_CTA:CTA_Link {
              Primary_URL
            }
            Other_Links {
              id
              Label
              Link_Image{
                alternativeText
                url

                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 880
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }
              }
              Link {
                Primary_URL
                
                Main_Parent {
                  Primary_URL
                }
                Secondary_Menu {
                  Primary_URL
                }
                nested_menus {
                  Primary_URL
                }
              }
            }            
          }
          ... on GLSTRAPI_ComponentComponentShowNewsInsights {
            id
            Show_Hide
            Title
            Section_BG_Color
            Tab_List {
              id
              Tab_Name
              insights {
                id
                Title
                URL 
                Embed_Video_URL
                news_and_insights_category: insight_category{
                  id
                  Name:Category
                }
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
                Image {
                  alternativeText
                  url
                }
              }            
            }
          }
          ... on GLSTRAPI_ComponentComponentThreeBlocksComponents {
            id
            Three_Blocks_Modules {
              Content
              Heading
              Custom_Link
              Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 920
                      quality: 70
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )    
                  }
                }
              }
              id
              menu_link {
                Primary_URL
                Secondary_URL

                Main_Parent {
                  Primary_URL
                }
                Secondary_Menu {
                  Primary_URL
                }
                nested_menus {
                  Primary_URL
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentOurPeopleComponent {
            id
            Heading
            Content
          }
          ... on GLSTRAPI_ComponentComponentLetGetStarted {
            id
            Video_Call_Link
            Phone_No
            Heading
            Contact_Email_Id
            Content
            Keep_In_Touch
          }

          ... on GLSTRAPI_ComponentComponentShowContactFormModule {
            id
            Show_Contact_Form
            Title
            Content
            May_Also_CTA_Buttons {
              CTA_Label
              CTA_Icon {
                url
                alternativeText
              }
              CTA_Link
              Icon_ClassName
            }
          }

          ... on GLSTRAPI_ComponentComponentClientsWeWorkWith {
            id
            _id
            Title
            Clients_Module {
              Link
              id
              Name
              Logo {
                alternativeText
                url
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentShowCustomerReviews {
            id
            Show_Customer_Reviews
            Review_BG_Color:Section_BG_Color
            Heading
            Background_Image{
              url
              alternativeText
            }
            reviews {
              Client_Name
              Content
              Sector
            }
          }

          ... on GLSTRAPI_ComponentComponentRelatedServices {
            id
            Title
            Content
            Service_BG_Color:Section_BG_Color
            Menu_Links {              
              Label
              Custom_Link
              Link {
                id
                Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP
                        width: 472
                        quality: 70
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )    
                    }
                  }
                }
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
                Label
                Primary_URL
                Secondary_URL
                Main_Parent {
                  Primary_URL
                }
                Secondary_Menu {
                  Primary_URL
                }
                nested_menus {
                  Primary_URL
                }
                
              }
            }
          }

          ... on GLSTRAPI_ComponentRightsideComponentsRightsideComponents {
            id
            Show_Subscribes
            Choose_People {
              id
              people {
                id
                Level
                Departments
                people_department{
                  id
                  Name
                }
                people_departments {
                  id
                  Name
                }
                people_services{
                  id
                  Name
                }
                Name
                Phone_Number
                Email_ID
                Form_Queue_ID
                URL
                Image {
                  alternativeText
                  url
                }
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
              }
            }
            Related_Services_List {
              id
              Title
              Menu_Links {
                Label
                Link {
                  Primary_URL
                  Image {
                    url
                    alternativeText
                  }
                  Main_Parent {
                    Primary_URL                 
                  }
                  Secondary_Menu {
                    Primary_URL
                  }
                  nested_menus {
                    Primary_URL
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentShowNewsListingPage {
            id
            Choose_Listing_Page
          }

          ... on GLSTRAPI_ComponentComponentCvFormComponent {
            id
            Title
            Content
            May_Also_CTA {
              CTA_Link
              CTA_Label
              Icon_ClassName
              CTA_Icon {
                alternativeText
                url
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentVacanciesComponent {
            id
            Show_Vacancies
          }

          ... on GLSTRAPI_ComponentComponentEmployeeSayComponent {
            id
            Employee_Say_Module {
              Embed_Video_Link
              Name
              Position
              id
              Upload_Image {
                alternativeText
                url
              }
            }
            Title
          }

          ... on GLSTRAPI_ComponentComponentHowWeGotHere {
            id
            Title
            How_We_Got_Module {
              Content
              Category
              id
              Year
              Image {
                alternativeText
                url
              }
            }
          }
          
        }
      }
    }
}`
