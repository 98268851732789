import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import {ImageModule} from "../../../modules/Image_Module"
import PlayVideo from '../../../components/Play/PlayVideo';
import NewsInsightsFilter from "../BannerFilter/NewsInsightsFilter"
import LandlordsFilter from "../BannerFilter/LandlordsFilter"
import CaseStudyFilter from "../BannerFilter/CaseStudyFilter"
// import PeopleFilter from "../BannerFilter/PeopleFilter"
import NewsInsightsSearch from "../BannerSearch/NewsInsightsSearch"
import PeopleSearch from "../BannerSearch/PeopleSearch"
import Banner_CTA_Module from "../../../modules/Banner_CTA_Module";

import $ from "jquery"

import "./StaticPageBanner.scss"

const StaticPageBanner = ({GQLPage, Module, openSearch, location}) => {

    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');
    
    const scrollToPos = (div_id) =>{
        var div_id_txt = div_id;
        if(div_id === "#contact" || div_id === "contact" || div_id === "#contact_office"){
            div_id_txt = "contact_office";
        }

        $('html, body').animate({
            scrollTop: $("#"+div_id_txt).offset().top - 200
        }, 'smooth');
    }
    return (
        <React.Fragment>
            <section className="insights-banner">
                <div className="overlay_bg"></div>
                <picture>
                    {GQLPage.Banner_Image && 
                        <ImageModule ImageSrc={GQLPage.Banner_Image} altText={GQLPage.Banner_Heading + " - Strettons"} imagename="articles.Banner_Image.inner_header" strapi_id={GQLPage.id} ggfx_results={GQLPage.ggfx_results} /> 
                    }
                </picture>
                <div className="insights-content">
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                        <Container>
                            <Row>
                                <Col>
                                    <div className="insights-box">
                                        <h1>{GQLPage.Banner_Heading}</h1>
                                        <p>{GQLPage.Banner_Content}</p>
                                    </div>

                                    {
                                        Module?.length > 0 && Module[0].Show_Search_Box && 
                                        <div className="insight-search">
                                            {(GQLPage.Alias === "insights" || GQLPage.Alias == "case_studies" || GQLPage.Alias == "landlord_resource_center") && <NewsInsightsSearch location={location} />}
                                            
                                            {GQLPage.Alias === "our-people" && <PeopleSearch location={location} />}
                                        </div>
                                    }

                                    <Banner_CTA_Module className="buttons" setVideoId={setVideoId} setPlay={setPlay} GQLPage={GQLPage} CTA_Module={Module} openSearch={openSearch} scrollToPos={scrollToPos} />

                                </Col>
                            </Row>
                        </Container>
                    </ScrollAnimation>
                </div>
            </section>

            {/* ======= Banner Filter ==========*/}  
            {(GQLPage.Alias === "insights") && <NewsInsightsFilter alias={GQLPage.Alias} />}
            {(GQLPage.Alias == "landlord_resource_center") && <LandlordsFilter alias={GQLPage.Alias} />}
            {(GQLPage.Alias == "case_studies") && <CaseStudyFilter alias={GQLPage.Alias} />} 

            
            {/* {GQLPage.Alias === "our-people" && <PeopleFilter />}          */}
            

            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={videoId}
                isAutoPlay={1}
            />

        </React.Fragment >
    );
};
export default StaticPageBanner;
